body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-upload-list {
  width: 300px;
}

.mySelfClassName {
  background-color: #43aff1;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.ant-table-row-hover,
.ant-table-row-hover>td {
  background: #87CEFA !important;
}

.ant-tree-switcher-icon {
  margin-top: 18px !important;
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}

.ant-tree-node-selected {
  background-color: #fff !important;
}

.ant-popover-title {
  display: none !important;
}

.ant-popover-inner-content {
  padding: 0px !important;
}

.loginContent {
  background: url(./images/sybjt.png);
  background-size: 100% 100%;
}